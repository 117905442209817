import React, { useEffect, useState } from 'react'
import Header from '../../../components/Yard/header/Header'
import "./Home.css"
import HomeCard from "./HomeCard"
import {Routes} from '../../../utils/route'
import {Spin } from 'antd';
import {usePosition} from '../../../utils/usePosition';
const Home = (props) => {
    const defaultSettings = {
        enableHighAccuracy: true,
        timeout: Infinity,
        maximumAge: 0,
    };
      const { latitude, longitude } = usePosition(false,defaultSettings);
    const [loading, setLoading] = useState (true);
    useEffect(()=>{
        const callback=(val)=>{
            if(val===true){
                setLoading(false);
            }
          }
          Routes('yard',callback)
    },[])
	if(!loading){
    return (
        <>
                <section className="home">
                <Header />
                <div className="home_container">
                <HomeCard />
                </div>
                </section>
        </>
    )
}else{
    return(<div className="spinner-main"><Spin/></div>)
  }
}
export default Home;