import { types } from './type';
import API from './../api';
import { navigate } from 'gatsby';
const { login } = API;
export const Auth = (data) => (dispatch) => {
  dispatch({ type: types.SHOW_AUTH_LOADING, loading: true });
  login(data)
    .then((res) => {
      if (res.data.success === true) {
        const _data = res.data.data;
        localStorage.setItem('token', _data.api_token);
        localStorage.setItem('role_id', _data.role_id);
        localStorage.setItem('data', JSON.stringify(_data));
        localStorage.setItem(
          'userName',
          _data.first_name + ' ' + _data.middle_name + ' ' + _data.last_name
        );
        dispatch({ type: types.SHOW_AUTH_LOADING, loading: false });
        dispatch({
          type: types.AUTHENTICATED,
          token: res.data.data.api_token,
        });
        if (_data.role_id > 50 && _data.role_id < 100) {
          // navigate('/dashboard');
          navigate('/myYard');
        } else if (_data.role_id > 40 && _data.role_id < 50) {
          navigate('/yard/home');
        } else if (_data.role_id === 999 || _data.role_id === 800) {
          navigate('/client');
        }
        else if (_data.role_id === 199) {
          navigate('/equipment-drop-off');
        }
      } else {
        dispatch({ type: types.SHOW_AUTH_LOADING, loading: false });
        dispatch({
          type: types.SHOW_AUTH_MESSAGE,
          message: res.data.message,
          showMessage: true,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: types.SHOW_AUTH_MESSAGE,
        message: 'Something went wrong',
        showMessage: true,
      });
      dispatch({ type: types.SHOW_AUTH_LOADING, loading: false });
    });
};

export const hideAuthMessage = (data) => (dispatch) => {
  dispatch({ type: types.SHOW_AUTH_MESSAGE, showMessage: false });
};

export const Logout=()=>(dispatch)=>{
  dispatch({ type: types.LOGOUT });
}